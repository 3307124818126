import { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import amber from "../../../assets/Logos/PartnerLogos/amber.png";
import trip from "../../../assets/Logos/PartnerLogos/trip.png";
import prodigy from "../../../assets/Logos/PartnerLogos/prodigy.jpg";
import bokz from "../../../assets/Logos/PartnerLogos/bokz.png";
import uhomes from "../../../assets/Logos/PartnerLogos/uhomes.png";

const partners = [
  {
    name: "Amber Students",
    image: amber,
    description: "Find your perfect student accommodation with Amber.",
    link: "https://amberstudent.com/?utm_source=unimayt&utm_medium=website&utm_content=homepage",
  },
  {
    name: "Trip.com",
    image: trip,
    description: "Explore offers and travel packages through Trip.com.",
    link: "https://www.trip.com/partners/account/profile?utm_source=unimayt&utm_medium=website&utm_content=homepage",
  },
  {
    name: "Bokz",
    image: bokz,
    description: "Find your perfect student accommodation with Bokz.",
    link: "https://bokz.co.uk/product/the-essential/?company_name=Unimayt",
  },
  {
    name: "Prodigy Finance",
    image: prodigy,
    description: "Financial solutions for international students.",
    link: "https://prodigyfinance.com/?utm_source=unimayt&utm_medium=website&utm_content=homepage&utm_campaign=partners-unimayt-referral-2024",
  },
  {
    name: "Uhomes",
    image: uhomes,
    description: "Discover ideal student housing options with Uhomes.",
    link: "https://en.uhomes.com/referral/partnerShare?xcode=000ac268a5c7a3e821e7",
  },
];

export default function TrustedWidget() {
  const [showTripPartner, setShowTripPartner] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % partners.length);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + partners.length) % partners.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % partners.length);
  };

  const handleNameClick = (partner: any) => {
    if (partner.link) {
      window.open(partner.link, "_blank");
    }
  };

  const handleClick = (partner: any) => {
    if (partner.name === "Trip.com") {
      setShowTripPartner(true);
    } else if (partner.link) {
      window.open(partner.link, "_blank");
    }
  };

  const handleClose = () => {
    setShowTripPartner(false);
  };

  return (
    <div className="bg-white px-4 lg:px-14 py-2">
      <div className="md:w-full mt-4 md:mt-4 text-left md:text-left text-light">
        <h2 className="text-subheading md:text-smheading font-heading mb-2 md:mb-4 md:mt-0 mt-4 text-dark md:text-left text-center">
          UNIMAYT'S TRUSTED PARTNERS
        </h2>

        <p className="text-Button md:text-paragraph text-subparagraph mb-4 md:px-8 px-2 md:mb-8 md:text-left text-center">
          At Unimayt, we take pride in collaborating with industry-leading
          partners to bring you the best services for your academic journey. Our
          carefully selected partners are trusted names in their respective
          fields, offering exceptional solutions for accommodation, travel,
          financing, and beyond. With Unimayt and our partners, you're always in
          safe hands.
        </p>

        <div className="md:hidden relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {partners.map((partner, index) => (
                <div
                  key={index}
                  className="w-full flex-shrink-0"
                  onClick={() => handleClick(partner)}
                >
                  <div className="bg-unimaytLight shadow-lg rounded-2xl p-4 mx-6 hover:shadow-xl transition-shadow duration-300">
                    <div className="flex flex-col items-center h-full">
                      <div className="w-full h-20 flex items-center justify-center mb-4">
                        <img
                          src={partner.image}
                          alt={`${partner.name} Logo`}
                          className="h-full w-auto object-contain rounded-xl transform hover:scale-105 transition-transform duration-300"
                        />
                      </div>
                      <h2
                        className="text-subheading font-heading mb-2 text-Button hover:text-primary cursor-pointer transform hover:scale-125 transition-transform duration-300"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNameClick(partner);
                        }}
                      >
                        {partner.name}
                      </h2>

                      <p className="text-dark text-center text-subparagraph flex-grow">
                        {partner.description}
                      </p>
                      <button className="text-Button font-bold transform hover:scale-105 transition-transform duration-300 mt-auto pt-4">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={handlePrev}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 pl-8"
          >
            <ChevronLeft className="w-6 h-6 text-light font-bold" />
          </button>
          <button
            onClick={handleNext}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 pr-8"
          >
            <ChevronRight className="w-6 h-6 text-light font-bold" />
          </button>
        </div>

        <div className="hidden md:grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 justify-center md:mb-16 mb-8 md:ml-8">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="bg-unimaytLight shadow-lg rounded-2xl p-6 hover:shadow-xl transition-shadow duration-300 cursor-pointer relative"
              onClick={() => handleClick(partner)}
            >
              <div className="flex flex-col items-center pb-12">
                <div className="w-full h-48 flex items-center justify-center mb-4">
                  <img
                    src={partner.image}
                    alt={`${partner.name} Logo`}
                    className="h-full w-auto object-contain rounded-xl transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <h2
                  className="text-subheading font-semibold mb-2 text-Button hover:text-primary cursor-pointer transform hover:scale-125 transition-transform duration-300"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNameClick(partner);
                  }}
                >
                  {partner.name}
                </h2>
                <p className="text-dark mb-2 text-center text-paragraph">
                  {partner.description}
                </p>
              </div>
              <button className="absolute bottom-8 left-1/2 -translate-x-1/2 text-Button font-bold hover:text-primary cursor-pointer transform hover:scale-105 transition-transform duration-300">
                Learn More
              </button>
            </div>
          ))}
        </div>

        {showTripPartner && (
          <>
            <div className="hidden md:flex mt-8 p-4 justify-center">
              <iframe
                src="https://www.trip.com/partners/ad/DB651548?Allianceid=5373131&SID=116159331&trip_sub1="
                style={{
                  width: "100%",
                  maxWidth: "760px",
                  height: "90px",
                }}
                id="DB651548"
              ></iframe>
              <button
                onClick={handleClose}
                className="absolute top-2 right-2 text-red-600 font-heading"
              >
                Close
              </button>
            </div>

            <div className="md:hidden mt-4 flex justify-center">
              <iframe
                src="https://www.trip.com/partners/ad/DB648692?Allianceid=5373131&SID=116159331&trip_sub1="
                style={{
                  width: "250px",
                  height: "280px",
                  border: "none",
                }}
                id="DB648692"
              ></iframe>
              <button
                onClick={handleClose}
                className="absolute top-2 right-2 text-red-600 font-bold"
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
