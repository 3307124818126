import axios from "axios";
import { API_BASE_URL, ENDPOINTS } from "../config/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { isTokenAboutToExpire } from "../hooks/isTokenAboutToExpire";
import { setExpireTime } from "../hooks/setExpireTime";
import { checkTokenExpire } from "../hooks/checkTokenExpire";

let isRefreshing = false;
let tokenNeedsRefresh = false;

const refreshTokens = async () => {
  return axios.post(
    `${API_BASE_URL}${ENDPOINTS.REFRESH}`,
    {},
    { withCredentials: true }
  );
};

const Instance = () => {
  const navigate = useNavigate();

  const instance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
  });

  const isTokenInvalidOrExpiring = () => {
    return isTokenAboutToExpire() || !checkTokenExpire();
  };

  instance.interceptors.request.use(
    (config) => {
      if (isTokenInvalidOrExpiring() && !config.url?.includes("/login")) {
        tokenNeedsRefresh = true;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    async (response) => {
      if (tokenNeedsRefresh && !isRefreshing) {
        isRefreshing = true;
        tokenNeedsRefresh = false;

        try {
          await refreshTokens();
          localStorage.setItem("exp", setExpireTime(120).toISOString());
        } catch (err) {
          toast.error("Session expired. Please log in again.");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("exp");
          navigate("/login");
        } finally {
          isRefreshing = false;
        }
      }

      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        toast.error("Session expired. Please log in again.");
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          navigate("/login");
          break;

        case 422:
          break;

        case 409:
          toast.error(error.response.data.message);
          break;

        case 500:
          break;

        default:
          break;
      }
      return Promise.reject(error);
    }
  );

  return { instance };
};

export default Instance;
