import { getTokenExpirationTime } from "./getTokenExpirationTime";

export const checkTokenExpire = () => {
  const expirationTime = getTokenExpirationTime();
  const currentTime = new Date();
  if (expirationTime !== null) {
    return currentTime.getTime() < expirationTime;
  } else {
    return false;
  }
};
