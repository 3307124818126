import React, { useCallback, useEffect } from "react";
import MyProfile from "../components/shared/profile/MyProfile";
import MainLayout from "../components/layout/MainLayout";
import { useQuery } from "@tanstack/react-query";
import UseUsers from "../services/useUsers";
import UseChat from "../services/useChat";
import { useLocalStorage } from "usehooks-ts";
import { Conversation } from "../types/ChatsInterface";
import { useUserStore } from "../zustandStore/useUserStore";

const Home: React.FC = () => {
  const { getUsers } = UseUsers();
  const { getChats } = UseChat();

  const setUsers = useUserStore((state) => state.setUsers);
  const [conversation, setConversation] = useLocalStorage<Conversation[]>(
    "conversation",
    []
  );

  const { data: usersData, isSuccess: usersSuccess } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const { data: chatsData, isSuccess: chatsSuccess } = useQuery({
    queryKey: ["chats"],
    queryFn: getChats,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const handleConversationUpdate = useCallback(
    (data: any) => setConversation(data?.data?.chats),
    [setConversation]
  );

  useEffect(() => {
    if (chatsSuccess && chatsData?.data) {
      handleConversationUpdate(chatsData);
    }
  }, [chatsData, chatsSuccess, handleConversationUpdate]);

  useEffect(() => {
    if (usersSuccess && usersData?.data) {
      setUsers(usersData?.data);
    }
  }, [usersData, usersSuccess, setUsers]);

  return (
    <MainLayout conversation={conversation} setConversation={setConversation}>
      <MyProfile isLoading={!usersSuccess} />
    </MainLayout>
  );
};

export default Home;
