import SecondaryHeader from "../components/layout/header/SecondaryHeader";

const UnimaytCareer = () => {
  return (
    <div className="w-full h-screen bg-unimaytLight">
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>
      <div className="text-smheading lg:text-heading font-heading text-dark whitespace-nowrap flex justify-center items-center pt-8">
        Unimayt Careers
      </div>
      <div className="text-light text-paragraph flex justify-center items-center">
        Comming Soon....
      </div>
    </div>
  );
};

export default UnimaytCareer;
